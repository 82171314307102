* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, system-ui;
}

body {
    overflow-x:hidden;
    /*height: 420rem;*/
}

.mobile-view{
    display: none;
}

.MobileContent{
    display: none;
}

.MobileItExportsImgDiv{
    display: none;
}

.OurExportsSpan2Mobile , .MobileAlcalineWorks , .FooterLinks2 , .MobileArrowNext {
    display: none;
}

.OurExportsDiv3button, .MobileArrowNext2btn, .ContactInputDivMobile{
    display: none;
}


.Menu {
    display: flex;
    width: 42rem;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
    margin-right: 3rem;
}

a {
    text-decoration: none;
}

nav {
    text-align: center;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px 0;
    /*position: absolute;*/
    /*gap: 8rem;*/
}

nav ul {
    list-style: none;
    width: 100%;
    text-align: right;
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #4A5568;

}

nav ul li {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: 0.3s;
    /*width: 200rem;*/
    text-align: center;
}

nav ul li:hover {
    transform: scale(1);
    color: #57007B;
    font-weight:bold;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #57007B);
    text-underline-offset: 6px;
}

.ElementSelectedNot {
    padding: 20px;
    overflow: hidden;
}

.ElementSelected {
    color: #57007B;
    font-weight:bold;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #57007B;
    text-underline-offset: 6px;
}


nav ul li a {
    color: black;
    text-decoration: none;
}

.dropbtn {
    list-style: none;
    width: 100%;
    text-align: right;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 510;
    line-height: 25px;
    color: #4A5568;
    background: none;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    text-align: center;
}

/* Links inside the dropdown */
.dropdown-content button {
    color: black;
    padding: 14px 28px;
    text-decoration: none;
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    transform: scale(1);
    color: #171717;
    font-weight:bold;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #F76680;
    text-underline-offset: 6px;
}



.logo {
    width: 180px;
    cursor: pointer;
    margin-right: 13rem;
}

.btn {
    width: 124px;
    height: 42px;
    padding-left: 25px;
    padding-right: 25px;
    /*background: linear-gradient(225deg, #F76680 0%, #57007B 100%);*/
    background: none;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    display: inline-flex;

    border: none;
	cursor: pointer;
	outline: inherit;
}

.btn-div {
    padding-top: 20px;
    margin-left: 5rem;
}

.btn-div a {
    text-decoration: none;
}

.Home {
    /*display: flex;*/
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 8rem ;
    /*background-color: black;*/
}

.Content {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.span-1 {
    color: #1A202C;
    font-size: 45px;
    font-weight: 300;
    line-height: 71px;
    word-wrap: break-word
}

.span-2 {
    color: #1A202C;
    font-size: 53px;
    font-weight: 800;
    line-height: 71px;
    word-wrap: break-word
}

.span-3 {
    font-size: 53px;
    font-weight: 800;
    line-height: 71px;
    word-wrap: break-word;

    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.span-4 {
    color: #4A5568;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word
}

.span-5 {
    color: #4A5568;
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    word-wrap: break-word;

    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.LetsConnect {
    width: 190px;
    height: 52px;
    padding: 20px 30px;
    /*background: #57007B;*/
    /*box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.15);*/
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    margin-top: 3rem;

    border: none;
	cursor: pointer;
	outline: inherit;
}

.LetsConnectBtn {
    /*color: #FAFAFA;*/
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 0.875rem;
    word-wrap: break-word;
}

.RoundIconTop {
    justify-content: center;
    display: flex;
    margin-right: 30rem;
    position: relative;
    z-index: -2;
}

.RoundIconBottom {
    justify-content: center;
    display: flex;
    margin-right: 75rem;
    margin-top: -1.8rem;
    position: relative;
    z-index: -3;
}

.BellowButton {
    width: 4rem;
    height: 4rem;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%); border-radius: 9999px;
}

.RoundGradiant {
    display: flex;
    justify-content: center;
    margin-top: -6.5rem;
    margin-right: 4rem;
    z-index: 0;
    height: 7rem;
}

.SliderHomeDiv {
    position: absolute;
    width: 100%;
    z-index: 6;
    margin-top: -2rem;
}

.Core {
    width: 100%;
    height: 38rem;
    background-color: #F9F9FF;
    border-bottom: 2px solid #E7DAED;
    position: absolute;
    z-index: -2;
}

.SliderDiv {
    justify-content: center;
    display: flex;
    margin-top: -2rem;
}

.SliderDivContainer {
    color: #1A202C;
    font-size: 2.1875rem;
    font-weight: 700;
    line-height: 10rem;
    word-wrap: break-word
}

.SlidersClass {
    width: 333px;
    height: 287px;
    background: #FAFAFA;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.10);
    position: relative;
    z-index: 6;
}

.SlidersClass:hover {
    margin-top: 2rem;
    border: 1px #F76680 solid;
    transition: 0.8s;
}

.SlidersClassDiv {
    width: 301px;
    height: 98px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    margin-top: 27px;
    margin-left: 16px;
}

.SlidersClassDiv2 {
    width: 58px;
    height: 58px;
    position: relative;
}

.SlidersClassDiv3 {
    width: 58px;
    height: 58px;
    left: 0;
    top: 0;
    position: absolute;
    background: #FAFAFA;
    border-radius: 9999px;
    border: 1px #F76680 solid
}

.SlidersClassImg {
    width: 34px;
    height: 34px;
    left: 12px;
    top: 12px;
    position: absolute;
}

.swiper1 {
    width: 100%;
    height: 21rem;
    margin-top: 1%;
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    position: relative;
    z-index: 5;
}

.SliderContent {
    width: 301px;
    /*height: 25px;*/
    /*color: #F76680;*/
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    word-wrap: break-word;
    /*padding-top: 15px;*/
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 80px;
    padding-bottom: 9px;
}

.SliderText {
    /*width: 21vw;*/
    color: #6F6F6F;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.68px;
    word-wrap: break-word;
    /*margin-top: 0.7rem;*/
}

.WhyChooseUsDiv{
    margin-top: 36rem;
}

.WhyChooseDiv {
    justify-content: center;
    display: flex;
    margin-right: 57.5rem;
    margin-top: 2rem;
}

.WhyChoose {
    color: #1A202C;
    font-size: 35px;
    font-weight: 700;
    line-height: 55px;
    word-wrap: break-word;
}

.ITExperts {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    gap: 8rem;
}

.ITExperts image {
    width: 601rem;
    height: 460rem;
    border-radius: 20px;
}

.ITExpertsSpan1 {
    color: #1A202C;
    font-size: 2.1875rem;
    font-weight: 400;
    line-height: 3.4375rem;
    word-wrap: break-word;
    margin-top: 2rem;
}

.ITExpertsSpan2 {
    font-weight: 700;
}

.ITExpertsSpan3 {
    color: #718096;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2.25rem;
    word-wrap: break-word
}

.ITExpertsSpan4 {
    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ItExportsImgDiv {
    position: relative;
    display: inline-block;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 20px;
    transition: 0.08s;
}

.ItExportsImgDiv:hover img {
    transform: scale(1.10);
    transform-origin: 50% 50%;
    transition: 0.08s;
}



.ITExpertsDiv1 {
    margin-top: 5rem;
}

.ITExpertsDiv2 {
    width: 535px;
    height: 181px;
    margin-top: 2rem;
}

.ITExpertsMargin {
    margin-top: 5rem;
}

.Gradiant {
    width: 69px;
    height: 5px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    margin-bottom: 1rem;
}

.Vector{
    display: flex;
    justify-content: center;
    gap: 9rem;
}

.Vector1 {
    width: 154.545px;
    height: 258.214px;
    flex-shrink: 0;
    stroke-width: 2px;
    /*margin-right: 11rem;*/
    margin-top: -1rem;
    animation: rotateImage 3s infinite linear;

}

.Vector2 {
    width: 154.545px;
    height: 258.214px;
    flex-shrink: 0;
    stroke-width: 2px;
    /*margin-right: -rem;*/
    animation: rotateImage 3s infinite linear;
}

@keyframes rotateImage {
  0%, 100% {
    transform: rotateZ(10deg); /* No rotation */
  }
  50% {
    transform: rotateZ(-1deg); /* Rotate to the left */
  }

}

.Vectordiv1 {
    /*width: 44.25px;*/
    /*height: 44.25px;*/
    position: absolute;
    margin-left: -2rem;
    margin-top: -1.75rem;
}

.Vectordiv2 {
    margin-bottom: -1rem;
    margin-left: -0.5rem;
}

.Vectordiv3 {
    width: 14.34px;
    height: 23.47px;
    left: 15.16px;
    top: 10.39px;
    position: absolute;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%)
}

/*.VectorSpan {*/
/*    display: flex;*/
/*}*/

.VectorSpan1 {
    color: #718096;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    word-wrap: break-word;
}

.VectorSpan2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    word-wrap: break-word;

    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.VectorSpanDiv {
    /*display: flex;*/
    /*justify-content: center;*/
    margin-top: 10rem;
    /*margin-right: 10rem;*/
}

.VectorSpanDiv1 {
    color: #718096;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 2.25rem;
    word-wrap: break-word;
}

.OurExports {
    position: relative;
    height: 55rem;
    background: #F7F7FA;
    margin-top: 5rem;
    z-index: -2;
}

.EllipseClass {
    display: flex;
    justify-content: center;
    margin-right: 38rem;
    padding-top: 2rem;
}

.Ellipse {
    width: 18px;
    height: 18px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    border-radius: 9999px;
}

.OurExportsDiv {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    gap: 2rem;
    overflow: hidden;
}

.MobileOurExportsSpan1 {
    display: none;
}

.OurExportsDiv2 {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    gap: 2rem;
    overflow: hidden;
    margin-top: 3rem;
}

.LineGradientSubDiv1 {
    width: 69px;
    height: 5px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%)
}

.OurExportsText {
    text-align: center
}

.OurExportsSpan1 {
    color: #1A202C;
    font-size: 2.1875rem;
    font-weight: 400;
    line-height: 3.4375rem;
    word-wrap: break-word
}

.OurExportsSpan2 {
    color: #1A202C;
    font-size: 2.1875rem;
    font-weight: 700;
    line-height: 3.4375rem;
    word-wrap: break-word
}

.ExportsImgDivMargin {
    display: flex;
    justify-content: center;
    margin: auto;
}

.ExportsImgDiv {
    position: relative;
    display: inline-block;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 13px;
    width: 323px;
    height: 379px;
    transition: 0.08s;

}

.ExportsImg {
    width: 323px;
    height: 379px;
    border-radius: 13px;
    cursor: pointer;
    overflow: hidden;
    display: block;
    transition: 0.08s;
}

.ExportsImgDiv:hover .ExportsImg {
    transform: scale(1.10);
    transform-origin: 50% 50%;
    transition: 0.08s;
}

.ExportsName {
    color: #171717;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.275rem;
    text-align: center;
}

.ExportsRole {
    color: #757575;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6rem;
    text-transform: uppercase;
    text-align: center;
}

.ExportsEllipseRight {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    stroke-width: 2px;
    border-radius: 9999px;
    border: 2px #57007B solid;
	cursor: pointer;
    background: #F7F7FA;

    justify-content: center;
    align-items: center;
    display: inline-flex;
	outline: inherit;
}

.ArrowBack {
    padding-top: 0.2rem;
    padding-left: 0.2rem;
    /*margin: auto;*/
}

.ArrowNext {
    padding-top: 0.2rem;
    padding-left: 0.2rem;
}



.ExportsEllipseLeft {
    background: #57007B;
    box-shadow: 0 14px 44px rgba(14.31, 19.82, 34, 0.08);
}

.OurExportsArrow {
    gap: 1rem;
    margin-top: 3rem;
    position: relative;
    z-index: 2;
}

/*Our Approach*/

.OurApproach {
    height: 35rem;
    margin-top: 3rem;
    border-bottom: 1px #E7DAED solid;
}

.DevelopmentApproach{
    height: 67rem;
    margin-top: 3rem;
}

.TechStack {
    display: flex;
    justify-content: center;
    gap: 2.8rem;
    margin-top: 2rem;
    box-sizing: border-box;
}

.TechStackNotSelected:hover {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.875rem;
    word-wrap: break-word;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border: none;
    cursor: pointer;
}


.TechStackSelect {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.875rem;
    word-wrap: break-word;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border: none;
    cursor: pointer;
}

.TechStackNotSelected {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem; /* 166.667% */

    /*display: inline-flex;*/
	border: none;
	cursor: pointer;
    background: none;
}

.TechStackImg {
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
}

.TechStackImg image {
    width: 160px;
    height: 80px;
}

.TechStackLineGradient {
    width: 25px;
    height: 5px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    margin: auto;
    border-radius: 1rem;
}

.Work {
    height: 45rem;
    margin-top: 4rem;
    /*border-bottom: 1px #E7DAED solid;*/
}

.AlcalineWorks {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-right: 3rem;
    gap: 5rem;
}

.AlcalineWorksBottom {
    margin-top: 0;
    margin-left: 7.5rem;
}

.AlcalineWorkContainer {
    width: 299px;
    height: 159px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid #E7DAED;
    background: #FFF;
}

.AlcalineWorkContainerText {
    color: #1A202C;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.00563rem;
}

.AlcalineWorkContainerText2 {
    color: #718096;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.00438rem;
    word-wrap: break-word;
    margin-left: 1rem;
    margin-top: 0.5rem;
    width: 17em;
}

.GradiantTextColor {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.00563rem;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.AlcalineWorkContainerDiv {
    display: flex;
    gap: 0.5rem;
    margin-left: 1rem;
    margin-top: 1.5rem;
}

.MideLineComponent {
    display: flex;
    justify-content: center;
    /*margin-top: 5rem;*/
    /*margin-right: 3rem;*/
}

.ConnectWithUs{
    height: 25rem;
    display: flex;
    justify-content: center;
}

.ConnectWithUsDiv1{
    display: flex;
    width: 76.875rem;
    height: 17rem;
    flex-shrink: 0;
    border-radius: 20px;
    background: linear-gradient(225deg, #F1F1F5 0%, #E4ECF7 100%);
    gap: 15rem;
}

.ConnectWithUsText {
    margin: auto;
    text-align: center;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.4375rem; /* 157.143% */
    background: linear-gradient(225deg, #29272E 0%, #27272E 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ConnectWithUsButton {
    width: 221px;
    height: 50px;
    padding: 14px 25px;
    /*background: linear-gradient(225deg, #F76680 0%, #57007B 100%);*/
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
	border: none;
	cursor: pointer;
	outline: inherit;
    margin:auto;
    text-decoration: none;
}

.ConnectWithUsButtonText{
    /*color: #FAFAFA;*/
    font-size: 18px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
}

.ConnectWithUsImg{
    width: 119.456px;
    height: 190.999px;
    flex-shrink: 0;
    /*margin: auto;*/
    /*margin-right: 5rem;*/
}
.ConnectWithUsImgDiv {
    margin-right: -10.6rem;
    margin-top: 2.4rem;
}

.ConnectWithUsDiv2 {
    display: flex;
    justify-content: center;
    margin-right: 12rem;
}

.FooterBox {
    height: 29rem;
    /*justify-content: center;*/
    /*margin: auto;*/
    /*gap: 8rem;*/
}


.FooterBoxDiv {
    width: 100%;
    height: 24rem;
    display: flex;
    justify-content: center;
    /*margin-left: 1rem;*/
    border-bottom: 1px #E7DAED solid;;

}

.FooterLogo {
    width: 152px;
    height: 73.79px;
}

.FooterBoxText1 {
    width: 22rem;
    color: #718096;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem; /* 166.667% */
    word-wrap: break-word;
    margin-top: 1.5rem;
}

.FooterLinks {
    margin-top: 2rem;
    margin-left: 7rem;
}

.Link {
    color: #4A5568;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.00563rem;
}

.LinksName {
    width: 8rem;
    color: #718096;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 236.3%; /* 2.363rem */
}

.LinksName a {
    color: #718096;
    list-style: none;
    text-decoration: none;
}

.FooterContent {
    display: flex;
    justify-content: center;
    /*margin: auto;*/
    margin-top: 2.2rem;
}


.FooterContentText {
    color: #4A5568;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.625rem; /* 71.429% */
    letter-spacing: -0.00438rem;
}

.ApproachBox {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    gap: 2rem;
}

.Approach {
    width: 38rem;
    height: 15rem;
    border: 1px solid #E7DAED;
    background: #FAFAFA;
}

.ApproachContent{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-right: 2rem;
    gap: 1rem;
}

.ApproachDiv1 {
    width: 4rem;
    height: 3.6rem;
    /*width: 59px;*/
    /*height: 56px;*/
    background: linear-gradient(225deg, #29272E 0%, #27272E 100%);
    border-radius: 10px;
}

.ApproachIcon{
    display: flex;
    justify-content: center;
    /*width: 34px;*/
    /*height: 35px;*/
    flex-shrink: 0;
    /*margin: auto;*/
    margin-top: 0.68rem;
}

.ApproachDiv2 {
    padding-top: 0.2rem;
}

.ApproachDivText1 {
    color: #1A202C;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 136.523%; /* 1.70656rem */
}

.ApproachDivText2 {
    width: 27rem;
    color: #4A5568;
    font-feature-settings: 'salt' on;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 162.023%; /* 1.41769rem */
    margin-top: 1rem;
}

.SliderButtonDiv {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.5rem;
}

.SliderButton {
    width: 31px;
    height: 13px;
    border-radius: 5px;
    border: 1px solid var(--Base-White, #FFF);
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    cursor: pointer;
}

.SliderButtonRound {
    width: 13px;
    height: 13px;
    border-radius: 9999px;
    border: 1px #CBD5E0 solid;
    cursor: pointer;
}

.SliderButton {

}

.swiper-slide{
    width: fit-content;
}

.ContactUsdiv {
    display: flex;
    justify-content: center;
    margin: auto;
    font-size: 2.5em;
    font-weight: 800;
    line-height: 4.4375rem; /* 177.5% */

    -webkit-font-smoothing: antialiased;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%, #57007B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ContactUsdiv2 {
    display: flex;
    justify-content: center;
    margin: auto;
    color: #717171;
    font-size: 1.125rem;
}

.ContactUsMain {
    display: flex;
    justify-content: left;
    margin: auto;
    max-width: 70em;
    min-height: 35em;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
    margin-top: 2em;
}

.LinearGradientBox {
    width: 30rem;
    height: 40rem;
    background: linear-gradient(180deg, #43040F 0%, #57007B 100%, rgba(129.81, 31.45, 255, 0) 100%);
    border-radius: 10px;
}

.contactInfo {
    color: #FFF;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 3rem;
    margin-left: 2.5rem;
}

.contactInfo2 {
    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.8rem;
    margin-left: 2.5rem;
}

.ContactUsInfoDiv {
    display: grid;
    grid-template-columns: 1fr;
}

.ContactUsInfo3 {
    width: 343px;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5em;
}

.ContactUsInfo5 {
    margin-top: 7rem;
}

.ContactUsInfo4 {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 3rem;
    margin-right: 1em;
}

.LinearGradientBox2 {
    margin-left: 20rem;
    margin-top: -7rem;
}

.LinearGradientBox3 {
    width: 269px;
    height: 269px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 9999px;
}

.LinearGradientRound1 {
    width: 138px;
    height: 138px;
    background: rgba(255, 248.63, 248.63, 0.13);
    border-radius: 9999px;
    margin-left: 18rem;
}

.LinearGradientRound2 {
    width: 72px;
    height: 72px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    border-radius: 9999px;
    margin-top: -11.8em;
}

.ContactUsMainInput {
    /*width: 535px;*/
    /*height: 647px;*/
}

.MobileContentUsForm {
    display: none;
}

.ContactInputDiv {
    display: flex;
    margin-top: 2rem;
    margin-left: 3rem;
    gap: 3rem
}

.ContactUsText {
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 166.667% */
    margin-top: 2rem;
}

.ContactUsTextInput {
    border: none;
    border-bottom: 1px solid black;
    width: 15rem;
    margin-top: 0.5rem;
    font-size: 0.875rem;

    font-style: normal;
    font-weight: 500;
    line-height: 2rem; /* 142.857% */
}

.ContactUsTextInput2 {
    width: 33rem;
}

input:focus {
    outline: none;
}

.ContactSubjectDiv {
    margin-top: 3rem;
    margin-left: 3rem;
}

.TickCircle {
    display: flex;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: #E0E0E0;
    border-radius: 50%;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round-selected {
    background-color: black;
    display: block;
}

.checkbox-round-new {
    width: 1.3em;
    height: 1.3em;
    cursor: pointer;
    margin-top: 0.035rem;
}

.checkbox-round-new[type=radio]{
    accent-color: #57007B;
}


.TickCircleSolidFill {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 13px;
    height: 13.241px;
    background: #E0E0E0;
    border-radius: 9999px;
}

.ContactUsFormButton {
    display: inline-flex;
    padding: 19px 30px;
    justify-content: right;
    align-items: center;
    gap: 10px;

    border-radius: 5px;
    /*background: var(--Brand-Primary-bg, #57007B);*/

    /* Btn-Shadow */
    box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);

    color: #FAFAFA;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    word-wrap: break-word;
    margin-top: 3rem;
    margin-left: 26rem;
    cursor: pointer;
    /*background: #FFF;*/

}

.ContactUs {
    margin-bottom: 10rem;
}

.ContactUsSpan {
    font-size: 2.5rem;
}

.ContactUsInfoImg{
    margin-top: -3rem;
}

.mobileFooterLogo {
    width: 5rem;
    height: 5rem;
}

.TechStackImg2 {
    transition: opacity 0.3s ease-in-out; /* Smooth transition on opacity change */
}

.TechStackImgNotDisplay {
    display: none;
}


.bttn {
    font-size: 16px;
    line-height: 160%;
}

.bttn.bttn-primary{
    align-items: center;
    border: 1px solid rgba(229, 54, 23, .4);
    border-radius: 5px;
    color: #e53617;
    display: flex;
    gap: 16px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    transition: all .3s ease;
    z-index: 1;
}

.bttn.bttn-primary:after{
    background: linear-gradient(90deg, #ef8081 -39.31%, #f63132 20.53%, #9f1689 79.22%);
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s ease;
    width: 0;
    z-index: -1;
    border-radius: 5px;
}
.bttn.bttn-primary:hover{
    border-radius: 5px;
    border: 1px solid transparent;
    color:#fff;
}

.bttn.bttn-primary:hover:after{
    border-radius: 5px;
    left:0;
    width:100%;
}